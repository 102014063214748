.ScooterCommand__container {
    background-color: white;
    width: calc(100% - 80px);
    margin: 20px auto;
    padding: 20px;
    height: auto;
    border-radius: 20px;
}

.ScooterCommand__title {
    color: #4F4F4F;
}

.ScooterCommand__commands {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin-top: 10px;
}