.ButtonCommand {
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    width: auto;
    height: 40px;
    background-color: #E3F0FF;
    color: #2158A3;
    border: 1px solid #2158A3;
    border-radius: 8px;
    margin-bottom: 10px;
}
.ButtonCommand:hover {
    border-radius: 2px solid #2158A3;
}

.ButtonCommand_back {
    color: #EB5757;
    background-color: #EB57571A;
    border: 1px solid #EB5757;
}

.ButtonCommand_rent {
    background-color: #2158A3;
    color: white;
}

.ButtonCommand_back:hover {
    border-radius: 2px solid #EB5757;
}