.button {
    cursor: pointer;
    width: 144px;
    height: 39px;
    background-color: #2F80ED;
    color: white;
    border: none;
    border-radius: 8px;
    border-color: #2F80ED;
    font-size: 16px;
    line-height: 19px;
}

.button_disabled {
    cursor: default;
    background-color: gray;
}
