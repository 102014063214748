.ScooterPage__container {
    background-color: white;
    width: calc(100% - 100px);
    margin: 20px auto;
    padding: 30px;
    height: auto;
    border-radius: 20px;
}

.ScooterPage__title_scooter {
    color: #4F4F4F;
}
.ScooterPage__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ScooterPage_deactivation {
    width: 70%;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 600px) {
    .ScooterPage__flex {
        display: flex;
        flex-direction: column;
    }
  }