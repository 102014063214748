* {
    margin: 0;
    padding: 0;
}

.Auth__container {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 420px;
    height: 250px;
    border-radius: 5px;
    background-color: white;
}

.Auth__form {
    height: 220px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.Auth__title {
    margin-top: 20px;
    font-size: 24px;
    line-height: 24px;
    font-weight: bolder;
    color: #4F4F4F;
}

.Auth__phone {
    margin-top: 10px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
}

.Auth__input {
    margin-top: 20px;
    padding: 10px;
    height: 20px;
    border: 1px solid #BDBDBD;
}

.Auth__input::placeholder {
    color: #BDBDBD;
    font-size: 14px;
    line-height: 40px;
}

.Auth__button {
    margin-top: 20px;
    align-self: flex-end;
}

.Auth__error {
    font-size: 14px;
    color: red;

}
.Auth__snackbar {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px;
    transition: all 0.3s ease-in-out;
  }

@media screen and (max-width: 600px) {
    .Auth__container {
        width: calc(100% - 20px);
        height: 250px;
        border-radius: 5px;
        background-color: white;
    }
  }