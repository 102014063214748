.ScooterInfo__container {
    border: 1px solid rgba(12, 58, 120, 0.3);
    border-radius: 8px;
    margin-top: 10px;
}
.ScooterInfo__ceil {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:0px 10px;
    width: 400px;
    height: 40px;
    border-bottom: 1px solid rgba(12, 58, 120, 0.3);
}
.ScooterInfo__ceil div:first-child {
    font-size: 0.9em;
    font-weight: bold;
}
.ScooterInfo__ceil:last-child {
    border-bottom: none;
}
.ScooterInfo__button {
    cursor: pointer;
    color: #2F90F3;
    text-decoration: underline;
    width: 100%;
    display: block;
    text-align: center;
    
    font-size: 1.25em;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .ScooterInfo__ceil {
        width: 90%;
    }
  }