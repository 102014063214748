.Map__container {
  width: 100%;
  background-color: #ffffff;
}

.Map__title {
  color: #4F4F4F;
  margin-top: 20px;
  padding-left: 20px;
}


@media screen and (max-width: 600px) {
  .Map__container {
    width: 100%;
    margin: 0;
  }

}