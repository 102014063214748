.ButtonStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 29px;
    color: white;
    background-color: #27AE60;
    text-align: center;
    border-radius: 8px;
}
.ButtonStatus_online {
    background-color: #27AE60;
}
.ButtonStatus_offline {
    background-color: #EB5757;
}
.ButtonStatus_rent {
    background-color: #2F80ED;
}
.ButtonStatus_not_rent {
    background-color: gray;
}