
th {
    color: #333;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    border: 1px solid rgba(12, 58, 120, 0.3);
  }

td {
    background-color: #F4F4F4;
    line-height: 30px;
    padding: 10px;
    border: 1px solid rgba(12, 58, 120, 0.3);
}

.ScooterList__container {
    background-color: white;
    width: calc(100% - 60px);
    margin: 20px auto;
    overflow-x: auto;
/*     overflow-y: auto; */
    padding: 20px;
/*     height: 650px; */
    height: auto;
    border-radius: 20px;
}

.ScooterList__title {
    color: #4F4F4F;
}
.ScooterList__table {
    margin-top: 30px;
    border-collapse: collapse;
}
.ScooterList__url {
    color: #2F80ED;
}

.MuiInputBase-root {
    max-height: 40px;
}
