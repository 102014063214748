.ScooterDeactivation__container {
    background-color: white;
    width: auto;
    margin-left: 20px;
    height: auto;
    border-radius: 20px;
}


.ScooterDeactivation__title {
    word-wrap:break-word;
    color: #4F4F4F;
}

.ScooterDeactivation__commands {
    display: flex;
    height: auto;
    margin-top: 10px;
    flex-wrap: wrap;
}
.ScooterDeactivation__comment input {
  padding-left: 5px;
  margin: 20px 10px;
  width: 80%;
  height: 30px;
  border-radius: 1px solid #BDBDBD;
}
.ScooterDeactivation__snackbar {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px;
    transition: all 0.3s ease-in-out;
  }

  /* .ScooterDeactivation__snackbar_success {
    background-color: #4caf50;
    color: #fff;
  }
  
  .ScooterDeactivation__snackbar_error {
    background-color: #f44336;
    color: #fff;
  } */
@media screen and (max-width: 600px) {
    .ScooterDeactivation__container {
        margin-left: 0px;
    }
  }
