/* image-rendering: pixelated;
image-rendering: -moz-crisp-edges;
image-rendering: -o-crisp-edges; 
image-rendering: -webkit-optimize-contrast;
image-rendering: crisp-edges;

*/
.map__marker {
	position: relative;
	width: 32px;
	height: 48px;
}

.map__marker_circle {
	position: absolute;
	top: 0;
	left: 0;
	width: 32px;
	height: 32px;

	background-color: #478fcc;
	border-radius: 50%;
	border: 2px solid #fff;
}
.map__marker_circle::before {
	z-index: -1;
	content: "";
	position: absolute;
	top: 0px;
	left: 0px;
	width: 32px;
	height: 32px;

	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 50%;
}
.map__marker_arrow {
	position: absolute;
	top: 16px;
	left: 2px;

	width: 0;
	height: 0;
	border-left: 14px solid transparent;
	border-right: 14px solid transparent;
	border-top: 32px solid #fff;
}
.map__marker_arrow::before {
	content: "";
	z-index: -1;
	position: absolute;
	top: -30px;
	left: -12px;

	width: 0;
	height: 0;
	border-left: 14px solid transparent;
	border-right: 14px solid transparent;
	border-top: 32px solid rgba(0, 0, 0, 0.4);
}
.map__marker_content {
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	position: absolute;
	top: 0;
	left: 0;
	text-align: center;

	width: 32px;
	height: 32px;
	line-height: 32px;

	background-repeat: no-repeat;
	background-position: 8.5px center ;
	background-size: 18px 18px;

	image-rendering: pixelated;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	image-rendering: crisp-edges;
}
