.map__container {
    width: 100%;
    height:  80vh;
  }

.map__container > * {
    height: 100%;
    width: 100%;
}

.leaflet-control-attribution {
  display: none;
}

.map__filters {
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 20px 20px;
}

.map__filters input {
  padding-left: 5px;
  margin: 0 10px;
  width: 70px;
  height: 25px;
  border-radius: 1px solid #BDBDBD;
}

@media screen and (max-width: 600px) {
  .map__container {
    width: 100%;
  }
  .map__filters {
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    
  }

}