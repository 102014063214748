.navigation {
    overflow: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    height: 40px;

}

.active {
    color: #2D9CDB;
    font-weight: bold;
}

.navigation ul {
    width: 100%;
    display: flex;
    justify-content: start;
    list-style-type: none;
}

.navigation ul li {
    padding-left: 30px;
}

.navigation ul li:last-child {
    margin-left: auto;
}

.navigation__logout {
    margin-right: 30px;
}

.navigation__logout:hover {
    color: #2D9CDB;
}

@media screen and (max-width: 600px) {
    .navigation ul {
        justify-content: space-between;
    }

    .navigation ul li {
        padding-left: 20px;
    }
    .navigation ul li:last-child {
        margin-left: 0px;
    }
}