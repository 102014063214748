.StatusScooter__container {
    display: flex;  
    align-items: center;
    background-color: white;
    height: 50px;
    width: auto;
    padding: 20px;
    border-radius: 20px;
}

.StatusScooter__title {
    text-align: center;
    color: #4F4F4F;
}
.StatusScooter__button {
    padding: 0px 10px;
    color: white;
    font-weight: bold;
    margin-left: 10px;
    width: auto;
    height: 50px;
    border-radius: 8px;
}
.StatusScooter__button_active {
    background-color: #219653;
    border: 1px solid #219653;
}
.StatusScooter__button_charging {
    background-color: #F2994A;
    border: 1px solid #F2994A;
}
.StatusScooter__button_deactivation {
    background-color: #EB5757;
    border: 1px solid #EB5757;
}