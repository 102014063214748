
body {
    margin: 0;
    background-image: url("/public/background-image.png");
    background-size: cover; 
    font: 1em sans-serif;
    min-width: 390px;
}

a {
    color: black;
    text-decoration: none;
}

.MuiDataGrid-main > div:last-child {
	display: none;
}
.MuiDateRangeCalendar-root > div:first-child {
	display: none;
}

@import url('./fonts.css');
