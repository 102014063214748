.leaflet-container {
	width: 100%;
	height: 100%;
	/* height: 100vh; */
}
.leaflet-control-attribution {
	display: none;
}

.leaflet-popup-content {
	margin: 0;
}