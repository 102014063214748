.Popup__container {
    width: 230px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.Popup__container>div:not(:first-child)  {
    margin-left: 10px;
}

.Popup__container>div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30px;
    background-color: #DBEBFF;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.Popup__arrow {
    width: 45px;
    height: 45px;
}
.arrow {
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-left: 0.5em;
    content: '';
    border-top: 0.15em solid currentColor;
    border-right: 0.15em solid currentColor;
    transform: rotate(45deg);
}