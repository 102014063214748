* {
    margin: 0;
    padding: 0;
}


.container {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 420px;
    height: 250px;
    border-radius: 5px;
    background-color: white;
}

.Auth {
    height: 220px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;

}

.Auth__title {
    margin-top: 20px;
    font-size: 24px;
    line-height: 24px;
    font-weight: bolder;
    color: #4F4F4F;
}
.Auth__text_send {
    margin-top: 10px;
    font-size: 16px;
    color: #828282;
}
.Auth__back{
    margin-top: 10px;
    text-align: center;
    text-decoration: none;
    color: #2F80ED;
}
.code {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.code__box {
    margin-top: 20px;
    text-align: center;
    font-weight: bolder;
    width: 45px;
    height: 45px;
    background-color: #EBF4FA;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    outline: none;
}



@media screen and (max-width: 600px) {
    .container {
        width: calc(100% - 20px);
        height: 250px;
    }
  }